import api from '@/api'

export default {
  state: {},
  actions: {
    async LOAD_DISASTER_LIST() {
      try {
        const res = await api.disasters.loadList()
        const {status, data} = res
        if (status === 200) {
          return data
        } else {
          throw `Load disaster list error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    async LOAD_DISASTER_BY_ID(context, id) {
      try {
        const res = await api.disasters.loadDisasterById(id)
        const {status, data} = res
        if (status === 200) {
          return data
        } else {
          throw `Load disaster by id error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    async DISASTERS_IMPORT(context, form) {
      try {
        const res = await api.disasters.importData(form)
        const {status, headers} = res
        if (status === 201) {
          return headers.location
        } else {
          throw `Import disasters error, status:${status}`
        }
      } catch (err) {
        throw err
      }
    },
    async ADD_DISASTER(context, data) {
      try {
        const {status} = await api.disasters.addDisaster(data)
        if (status === 201) {
          return true
        } else {
          throw `Add disaster error, status - ${status}`
        }
      } catch (err) {
        throw err
      }
    },
    async UPDATE_DISASTER(context, payload) {
      try {
        const {id, data} = payload
        const {status} = await api.disasters.updateDisaster(id, data)
        if (status === 204) {
          return true
        } else {
          throw `Update disaster error, status - ${status}`
        }
      } catch (err) {
        throw err
      }
    },
    async DELETE_DISASTER(context, id) {
      try {
        const {status} = await api.disasters.deleteDisaster(id)
        if (status === 200) {
          return true
        } else {
          throw `Delete disaster error, status - ${status}`
        }
      } catch (err) {
        throw err
      }
    }
  }
}