/**---------  DISASTERS  ---------------------*/

import {type_json, type_multipart_form} from './utils'

/**
 * @typedef disaster
 * @property {number} id - id
 * @property {string} road_id - road_id
 * @property {string} key - key
 * @property {string} direction - direction
 * @property {number} latitude - latitude
 * @property {number} longitude - longitude
 * @property {number} confirmed_risks - confirmed_risks
 * @property {number} critical_services - critical_services
 * @property {number} alternative_route - alternative_route
 * @property {number} flooding_confirmed - flooding_confirmed
 * @property {number} landslide_confirmed - landslide_confirmed
 * @property {number} erosion_confirmed - erosion_confirmed
 * @property {number} flooding_history - flooding_history
 * @property {number} landslide_history - landslide_history
 * @property {number} erosion_history - erosion_history
 * @property {number} cut_slope_angle - cut_slope_angle
 * @property {number} cut_slope_height - cut_slope_height
 * @property {number} cut_slope_protection - cut_slope_protection
 * @property {number} embankment_slope_angle - embankment_slope_angle
 * @property {number} distance_to_road - distance_to_road
 * @property {number} embankment_slope_protection - embankment_slope_protection
 * @property {number} drainage_condition - drainage_condition
 * @property {number} soil_type - soil_type
 * @property {number} precipitation - precipitation
 * @property {number} temperature - temperature
 * @property {number} import_id - import_id
 */

/**
 * @typedef disasterDto
 * @property {number} id - id
 * @property {string} road_id - road_id
 * @property {string} key - key
 * @property {string} direction - direction
 * @property {number} latitude - latitude
 * @property {number} longitude - longitude
 * @property {number} confirmed_risks - confirmed_risks
 * @property {number} critical_services - critical_services
 * @property {number} alternative_route - alternative_route
 * @property {number} flooding_confirmed - flooding_confirmed
 * @property {number} landslide_confirmed - landslide_confirmed
 * @property {number} erosion_confirmed - erosion_confirmed
 * @property {number} flooding_history - flooding_history
 * @property {number} landslide_history - landslide_history
 * @property {number} erosion_history - erosion_history
 * @property {number} cut_slope_angle - cut_slope_angle
 * @property {number} cut_slope_height - cut_slope_height
 * @property {number} cut_slope_protection - cut_slope_protection
 * @property {number} embankment_slope_angle - embankment_slope_angle
 * @property {number} distance_to_road - distance_to_road
 * @property {number} embankment_slope_protection - embankment_slope_protection
 * @property {number} drainage_condition - drainage_condition
 * @property {number} soil_type - soil_type
 * @property {number} precipitation - precipitation
 * @property {number} temperature - temperature
 * @property {number} import_id - import_id
 */

export default Api => ({


    /**
     * Disaster list
     * @returns Array<{Object}>
     */
    loadList() {
        return Api.get(`/disasters/`, {
            headers: {
                ...type_json
            }
        })
    },
    /**
     * Disaster by id
     * @param id {integer} disaster ID
     * @returns {Object}
     */
    loadDisasterById(id) {
        return Api.get(`/disasters/${id}`, {
            headers: {
                ...type_json
            }
        })
    },
    /**
     * Import Disasters by csv file
     * @param form {object} form
     * @returns {}
     */
    importData(form) {
        return Api.post(`/disasters/import`, form, {
            headers: {
                ...type_multipart_form
            }
        })
    },
    /**
     * Add Disaster
     * @param {disaster} data - the new disaster params
     * @return Promise<>
     */
    async addDisaster(data) {
        return Api.post(`/disasters`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Update Disaster params
     * @param {string} disasterId - Disaster ID
     * @param {disasterDto} data - Disaster params
     * @return Promise<>
     */
    async updateDisaster(disasterId, data) {
        return Api.put(`/disasters/${disasterId}`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Delete Disaster
     * @param {string} disasterId - Disaster ID
     * @return Promise<>
     */
    async deleteDisaster(disasterId) {
        return Api.delete(`/disasters/${disasterId}`, {
            headers: {
                ...type_json
            }
        })
    },
})