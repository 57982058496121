/** ------------ BRIDGES ------------------- */

import {type_json} from './utils'

export default Api => ({

    /**
     * list of bridges
     * @return {Promise<*> Array}
     * @returns {Array}
     * @return {Object}
     * @throws Error
     */
    async load_list() {
        return Api.get(`/bams/bridges/list`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * list of locations
     * @return {Promise<*> Array}
     * @return {Object}
     * @throws Error
     */
    async load_location_struct() {
        return Api.get(`/bams/location-struct`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * list of inspectors
     * @return {Promise<*> Array}
     * @returns {Array<String>>}
     * @throws Error
     */
    async load_inspector_list() {

        return Api.get(`/bams/bridges/inspector-list`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * list of bridges elements
     * @return {Promise<*> Array}
     * @returns {Array<String>>}
     * @throws Error
     */
    async load_element_list() {
        return Api.get(`/bams/bridges/element-list`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * list of bridges works
     * @return {Promise<*> Array}
     * @returns {Array<String>>}
     * @throws Error
     */
    async load_work_list() {
        return Api.get(`/bams/bridges/work-list`, {
            headers: {
                ...type_json
            }
        })
    },


    /**
     * list of bridges element materials
     * @return {Promise<*> Object}
     * @returns {Array<String>>}
     * @throws Error
     */
    async load_element_materials() {
        return Api.get(`/bams/bridges/element-materials`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * load bridge data by bridge_id
     * @param {String} bridge_id
     * @return {Promise<*> [data]}
     * @return {Array}
     * @throws Error
     */
    async load_data_by_id(bridge_id) {
        return Api.get(`/bams/bridges/${bridge_id}`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Add Bridge item
     * @param {Object} {
     * @param {string} bridge_id
     * @param {string} ...}
     * @return Promise<>
     */
    async add(data) {
        return Api.post(`/bams/bridges/add`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Update Bridge item
     * @param {Object} {
     * @param {number} bridge_id
     * @param {string} ...}
     * @return Promise<>
     */
    async update(id, data) {
        return Api.post(`/bams/bridges/upd/${id}`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Delete Bridge
     * @param {string} bridgeId - bridge ID
     * @return Promise<>
     */
    async delete(bridgeId) {
        return Api.delete(`/bams/bridges/${bridgeId}`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * load bridge inventory data by bridge_id
     * @param {String} bridge_id
     * @return {Promise<*> [data]}
     * @return {Array}
     * @throws Error
     */
    async load_inventory(bridge_id, insp_id = 'last') {
        return Api.get(`/bams/bridges/${bridge_id}/inventories/${insp_id}`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * add bridge inventory data by bridge_id
     * @param {String} bridge_id
     * @param {Array} data inventory inspection data
     * @return {Promise<*>}
     * @throws Error
     */
    async add_inventory_inspection(bridge_id, data) {
        return Api.post(`/bams/bridges/${bridge_id}/inventories/add`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * load bridge condition data by bridge_id
     * @param {String} bridge_id
     * @param {String} inspection_id
     * @return {Promise<*> [data]}
     * @return {Array}
     * @throws Error
     */
    async load_condition(payload) {
        const {bridge_id, inspection_id = 'last'} = payload
        return Api.get(`/bams/bridges/${bridge_id}/conditions/${inspection_id}`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * load bridge condition data by bridge_id
     * @param {String} bridge_id
     * @param {Array} data condition inspection data
     * @return {Promise<*>}
     * @throws Error
     */
    async add_condition_inspection(bridge_id, data) {
        return Api.post(`/bams/bridges/${bridge_id}/conditions/add`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * load bridge intervention data by bridge_id
     * @param {String} bridge_id
     * @return {Promise<*> [data]}
     * @return {Array}
     * @throws Error
     */
    async load_intervention(payload) {
        const {bridge_id, insp_id = 'last'} = payload

        return Api.get(`/bams/bridges/${bridge_id}/interventions/${insp_id}`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * add bridge intervention data by bridge_id
     * @param {String} bridge_id
     * @param {Array} data inventory inspection data
     * @return {Promise<*>}
     * @throws Error
     */
    async add_intervention_inspection(bridge_id, data) {
        return Api.post(`/bams/bridges/${bridge_id}/interventions/add`, data, {
            headers: {
                ...type_json
            }
        })
    }
})
