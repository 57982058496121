import api from '@/api'

export default {
    state: {},
    actions: {
        async LOAD_SUBNETWORK_LIST(context, category) {
            try {
                const res = await api.subNetworks.loadSubnetworkList(category)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load asset collection list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_SUBNETWORK_BY_ID(context, id) {
            try {
                const res = await api.subNetworks.loadSubnetworkById(id)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load subnetwork by id error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_ROADCODES_LIST() {
            try {
                const res = await api.subNetworks.load_roadcodes_list()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load roadcode list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async ADD_SUBNETWORK(context, payload) {
            try {
                const {data} = payload
                const {status} = await api.subNetworks.addSubnetworkItem(data)
                if (status === 201) {
                    return true
                } else {
                    throw `Add Subnetwork error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async UPD_SUBNETWORK(context, payload) {
            try {
                const {net_id, data} = payload
                const {status} = await api.subNetworks.updSubnetworkItem(net_id, data)
                if (status === 200) {
                    return true
                } else {
                    throw `Update Subnetwork error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async DELETE_SUBNETWORK(context, id) {
            try {
                const {status, request} = await api.subNetworks.deleteSubnetworkItem(id)
                if (status === 200) {
                    return true
                } else {
                    throw request.responseText
                }
            } catch (err) {
                throw err
            }
        },
    }
}
