import api from '@/api'

export default {
    state: {},
    actions: {
        // LOAD_UNIT_COSTS_LIST loads system unit costs list
        async LOAD_UNIT_COSTS_LIST() {
            try {
                const res = await api.unitCosts.load_unit_costs_list()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw 'Load MUC list error'
                }
            } catch (err) {
                throw err
            }
        },

        // LOAD_UNIT_COSTS_TABLE_LIST loads system unit costs list
        async LOAD_UNIT_COSTS_TABLE_LIST() {
            try {
                const res = await api.unitCosts.load_unit_costs_table_list()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw 'Load MUC list error'
                }
            } catch (err) {
                throw err
            }
        },

        // GET_UNIT_COSTS_BY_ID gets unit costs item by id

        async GET_UNIT_COSTS_BY_ID(context, id) {
            try {
                const res = await api.unitCosts.get_unit_costs_by_id(id)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Get unit costs by id=${id} error`
                }
            } catch (err) {
                throw err
            }
        },
        async ADD_UNIT_COSTS(context, payload) {
            try {
                const {data} = payload
                const {status} = await api.unitCosts.add_unit_costs_item(data)
                if (status === 201) {
                    return true
                } else {
                    throw `Add MUC error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async UPD_UNIT_COSTS(context, payload) {
            try {
                const {id, data} = payload
                const {status} = await api.unitCosts.upd_unit_costs_item(id, data)
                if (status === 200) {
                    return true
                } else {
                    throw `Update MUC error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async DELETE_UNIT_COSTS(context, id) {
            try {
                const {status, request} = await api.unitCosts.deleteMucItem(id)
                if (status === 200) {
                    return true
                } else {
                    throw request.responseText
                }
            } catch (err) {
                throw err
            }
        },

        async LOAD_UNIT_COSTS_WORKS(context, mid) {
            try {
                const {status, data} = await api.unitCosts.load_unit_costs_works(mid)
                if (status === 200) {
                    return data
                } else {
                    throw 'Load unit costs item works error'
                }
            } catch (err) {
                throw err
            }
        },

        // GET_UNIT_COSTS_WORK_BY_ID gets unit costs work by id
        async GET_UNIT_COSTS_WORK_BY_ID(context, {muc_id, work_id}) {
            try {
                const res = await api.unitCosts.get_unit_costs_work_by_id(muc_id, work_id)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Get unit costs work by mid=${muc_id} and work_id=${work_id} error`
                }
            } catch (err) {
                throw err
            }
        },

        // ADD_UNIT_COSTS_WORK creates unit costs work item
        async ADD_UNIT_COSTS_WORK(context, payload) {
            try {
                const {muc_id, data} = payload
                const {status} = await api.unitCosts.add_unit_costs_work(muc_id, data)
                if (status === 201) {
                    return true
                } else {
                    throw `Add MUC work error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },

        // UPD_UNIT_COSTS_WORK updates unit costs work item
        async UPD_UNIT_COSTS_WORK(context, payload) {
            try {
                const {work_id, muc_id, data} = payload
                const {status} = await api.unitCosts.upd_unit_costs_work(work_id, muc_id, data)
                if (status === 200) {
                    return true
                } else {
                    throw `Update MUC work error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },

        // GET_UNIT_COSTS_WORK_ITEM_CRITERIA gets unit costs work item criteria
        async GET_UNIT_COSTS_WORKS_CRITERIA(context, {mid, work_id}) {
            try {
                const res = await api.unitCosts.get_unit_costs_work_criteria(mid, work_id)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Get unit costs work by mid=${mid} and work_id=${work_id} error`
                }
            } catch (err) {
                throw err
            }
        },

        // GET_UNIT_COSTS_WORK_CRITERION_BY_ID gets unit costs work criterion by id
        async GET_UNIT_COSTS_WORK_CRITERION_BY_ID(
            context,
            {crit_id, work_id, muc_id}
        ) {
            try {
                const res = await api.unitCosts.get_unit_costs_work_criterion_by_id(
                    crit_id,
                    work_id,
                    muc_id
                )
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Get unit costs work criterion by mid=${muc_id} and work_id=${work_id} error`
                }
            } catch (err) {
                throw err
            }
        },

        // ADD_UNIT_COSTS_WORK_CRITERION creates unit costs work criterion item
        async ADD_UNIT_COSTS_WORK_CRITERION(context, payload) {
            try {
                const {work_id, muc_id, data} = payload
                const {status} = await api.unitCosts.add_unit_costs_work_criterion(
                    work_id,
                    muc_id,
                    data
                )
                if (status === 201) {
                    return true
                } else {
                    throw `Add MUC work criterion error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },

        // UPD_UNIT_COSTS_WORK_CRITERION updates unit costs work  criterion item
        async UPD_UNIT_COSTS_WORK_CRITERION(context, payload) {
            try {
                const {crit_id, work_id, muc_id, data} = payload
                const {status} = await api.unitCosts.upd_unit_costs_work_criterion(
                    crit_id,
                    work_id,
                    muc_id,
                    data
                )
                if (status === 200) {
                    return true
                } else {
                    throw `Update MUC work criterion error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_ASSET_TYPE_LIST(context, category) {
            try {
                const res = await api.unitCosts.load_asset_type_list(category)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load Asset type list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_MAINTENANCE_TYPE_LIST(context, category) {
            try {
                const res = await api.unitCosts.load_maintenance_type_list(category)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load maintenance type list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        }
    },
    mutations: {}
}
