import Vue from 'vue'
import Vuex from 'vuex'
import i18nStore from './i18n'
import Login from './login'
import Bridges from './bridges'
import Webgis from './webgis'
import Dashboard from './dashboard'
import Users from './users'
import Features from './features'
import Reports from './reports'
import UnitCosts from './unit-costs'
import Plans from './plans'
import Subnetworks from './subnetworks'
import Disasters from './disasters'
import Roads from "./roads";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    i18nStore,
    Login,
    Bridges,
    Webgis,
    Dashboard,
    Users,
    Features,
    Reports,
    UnitCosts,
    Plans,
    Subnetworks,
    Disasters,
    Roads
  }
})
