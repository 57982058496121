/**-----------------  PLANS ---------------*/
import {type_json} from './utils'

export default Api => ({
  /**
   * Get Plans list
   * @params {string} category
   * @return Promise<Array.<*>>
   * @returns {Array}
   * @returns {Object} [
   * @returns {number} {id
   * @returns {string} , name
   * @returns {string} , asset_type
   * @returns {string} , maintenance_type
   * @returns {string} , priority_calc
   * @returns {string} , muc_name
   * @returns {number} , muc_id
   * @returns {number} , budget
   * @returns {string} , asset_collection_name
   * @returns {number} , status
   * @returns {number} , cost_estimation
   * @returns {number} , asset_collection_id}]

   */
  async load_plan_list() {
    const params = {}
    return Api.get(
      `/plans`,
      {params},
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /**
   * Get Plan by ID
   * @params {string} id
   * @return Promise<Object.<*>>
   * @returns {Object}
   * @returns {number} {id
   * @returns {string} , name
   * @returns {string} , asset_type
   * @returns {string} , maintenance_type
   * @returns {string} , priority_calc
   * @returns {string} , muc_name
   * @returns {number} , muc_id
   * @returns {number} , budget
   * @returns {string} , asset_collection_name
   * @returns {number} , asset_collection_id}
   */
  async load_plan_by_id(plan_id) {
    return Api.get(`/plans/${plan_id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Get Plan works
   * @params {string} id
   * @return Promise<Object.<*>>
   * @returns {Object}
   */
  async load_plan_works(plan_id) {
    return Api.get(`/plans/${plan_id}/works`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Add Plan item
   * @param {Object} {
   * @param {string} name
   * @param {string} asset_type
   * @param {string} maintenance_type
   * @param {string} priority_calc
   * @param {string} muc_name
   * @param {number} muc_id
   * @param {number} budget
   * @param {string} asset_collection_name
   * @param {number} asset_collection_id}
   * @return Promise<>
   */
  async add_plan_item(item) {
    return Api.post(`/plans/add`, item, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Update Plan item
   * @param {Object} {
   * @param {number} id
   * @param {string} name
   * @param {string} asset_type
   * @param {string} maintenance_type
   * @param {string} priority_calc
   * @param {string} muc_name
   * @param {number} muc_id
   * @param {number} budget
   * @param {string} asset_collection_name
   * @param {number} asset_collection_id}
   * @return Promise<>
   */
  async upd_plan_item(id, data) {
    return Api.post(`/plans/upd/${id}`, data, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Delete Plan Item
   * @param {number} planId - plan ID
   * @return Promise<>
   */
  async deletePlanItem(planId) {
    return Api.delete(`/plans/${planId}`, {
      headers: {
        ...type_json
      }
    })
  },

  /**
   * Update Plan works selected item
   * @param {Object} {
   * @param {number} id
   * @param {Array} int work_id}
   * @return Promise<>
   */
  async change_plan_work_selected(id, data) {
    return Api.post(`/plans/upd-selected/${id}/`, data, {
      headers: {
        ...type_json
      }
    })
  }
})