import api from '@/api'

export default {
    state: {},
    actions: {
        async LOAD_BRIDGES_LIST() {
            try {
                const res = await api.bridges.load_list()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bridges list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_BRIDGE_INSPECTOR_LIST() {
            try {
                const res = await api.bridges.load_inspector_list()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bridge inspectors list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_BRIDGE_ELEMENT_LIST() {
            try {
                const res = await api.bridges.load_element_list()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bridge elements list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_BRIDGE_WORK_LIST() {
            try {
                const res = await api.bridges.load_work_list()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bridge works list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },

        // moved to roads file
        // async LOAD_ROAD_LIST() {
        //     try {
        //         const res = await api.roads.loadRoadList()
        //         const {status, data} = res
        //         if (status === 200) {
        //             return data
        //         } else {
        //             throw `Load road list error, status:${status}`
        //         }
        //     } catch (err) {
        //         throw err
        //     }
        // },

        async LOAD_BRIDGE_ELEMENT_MATERIALS() {
            try {
                const res = await api.bridges.load_element_materials()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bridge elements materials error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },

        async LOAD_BRIDGE_DATA_BY_ID(context, bridge_id) {
            try {
                const res = await api.bridges.load_data_by_id(bridge_id)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bridge data error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async ADD_BRIDGE(context, bridge) {
            try {
                const {status} = await api.bridges.add(bridge)
                if (status === 201) {
                    return true
                } else {
                    throw `Add Bridge error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async UPD_BRIDGE(context, payload) {
            try {
                const {bridge_id, data} = payload
                const {status} = await api.bridges.update(bridge_id, data)
                if (status === 200) {
                    return true
                } else {
                    throw `Update Bridge error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async DELETE_BRIDGE(context, id) {
            try {
                const {status, request} = await api.bridges.delete(id)
                if (status === 200) {
                    return true
                } else {
                    throw request.responseText
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_BRIDGE_INSPECTION_DATA(context, payload) {
            try {
                const {asset_type, asset_id, inspection_type} = payload
                const res = await api.files.load_bms_inspections({
                    asset_type,
                    asset_id,
                    inspection_type
                })
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bms inspections list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_BRIDGE_INVENTORY_DATA(context, bridge_id) {
            try {
                const res = await api.bridges.load_inventory(bridge_id)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bridge inventory data error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_BRIDGE_CONDITION_DATA(context, payload) {
            try {

                const res = await api.bridges.load_condition(payload)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bridge condition data error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_BRIDGE_INTERVENTION_DATA(context, payload) {
            try {
                const res = await api.bridges.load_intervention(payload)
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load bridge intervention data error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async ADD_BRIDGE_INVENTORY_INSPECTION(context, payload) {
            try {
                const {bridge_id, data} = payload
                const res = await api.bridges.add_inventory_inspection(bridge_id, data)
                const {status} = res
                if (status === 201) {
                    return 'ok'
                } else {
                    throw `Create bridge inventory data error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async ADD_BRIDGE_CONDITION_INSPECTION(context, payload) {
            try {
                const {bridge_id, data} = payload
                const res = await api.bridges.add_condition_inspection(bridge_id, data)
                const {status, headers} = res

                if (status === 201) {
                    const inspection_id = headers.location.split('/').pop(-1)
                    return inspection_id
                } else {
                    throw `Load bridge condition data error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async ADD_BRIDGE_INTERVENTION_INSPECTION(context, payload) {
            try {
                const {bridge_id, data} = payload
                const res = await api.bridges.add_intervention_inspection(bridge_id, data)
                const {status, headers} = res

                if (status === 201) {
                    const inspection_id = headers.location.split('/').pop(-1)
                    return inspection_id
                } else {
                    throw `Load bridge intervention data error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },

        async LOAD_BMS_INSPECTION_FILES(context, payload) {
            try {

                const res = await api.files.load_inspection_files(payload)
                const {status, data} = res

                if (status === 200) {
                    return data
                } else {
                    throw `Load bridge inspection files error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async ADD_INSPECTION_FILE(context, formData) {
            try {
                const res = await api.files.add_inspection_file(formData)
                const {status} = res
                if (status === 201) {
                    return true
                } else {
                    throw `Upload inspection file error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_LOCATION_STRUCT() {
            try {
                const res = await api.bridges.load_location_struct()
                const {status, data = []} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load location struct error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
    }
}
