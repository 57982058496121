/**---------  ROADS -----------------*/

import {type_json} from '@/api/utils'

export default Api => ({
    /**
     * @typedef road
     * @property road_id {string} - Road id
     * @property road_name {string} - Road name
     * @property road_category {string} - Road category
     * @property road_class {string} - Road class
     */

    /**
     * @typedef roadDto
     * @property road_name {string} - Road name
     * @property road_category {string} - Road category
     * @property road_class {string} - Road class
     */


    /**
     * Get Road list
     * @return Promise<road[]>
     */
    async loadRoadList() {
        return Api.get(`/bams/bridges/road-list`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Get Road list
     * @return Promise<road[]>
     */
    async loadRoadClassList() {
        return Api.get(`/bams/bridges/road-class-list`, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Add Road
     * @param {road} data - the new road params
     * @return Promise<>
     */
    async addRoad(data) {
        return Api.post(`/bams/bridges/road-list`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Update Road params
     * @param {string} roadId - Road ID
     * @param {roadDto} data - Road params
     * @return Promise<>
     */
    async updateRoad(roadId, data) {
        return Api.put(`/bams/bridges/road-list/${roadId}`, data, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Delete Road
     * @param {string} roadId - Road ID
     * @return Promise<>
     */
    async deleteRoad(roadId) {
        return Api.delete(`/bams/bridges/road-list/${roadId}`, {
            headers: {
                ...type_json
            }
        })
    },

})