import api from '@/api'

export default {
    state: {},
    actions: {
        async LOAD_ROAD_LIST(context, category) {
            try {
                const res = await api.roads.loadRoadList()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load road list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_ROAD_CLASS_LIST() {
            try {
                const res = await api.roads.loadRoadClassList()
                const {status, data} = res
                if (status === 200) {
                    return data
                } else {
                    throw `Load road class list error, status:${status}`
                }
            } catch (err) {
                throw err
            }
        },

        async ADD_ROAD(context, data) {
            try {
                const {status} = await api.roads.addRoad(data)
                if (status === 201) {
                    return true
                } else {
                    throw `Add road error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async UPDATE_ROAD(context, payload) {
            try {
                const {roadId, data} = payload
                const {status} = await api.roads.updateRoad(roadId, data)
                if (status === 204) {
                    return true
                } else {
                    throw `Update Road error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async DELETE_ROAD(context, roadId) {
            try {
                const {status} = await api.roads.deleteRoad(roadId)
                if (status === 200) {
                    return true
                } else {
                    throw `Delete Road error, status - ${status}`
                }
            } catch (err) {
                throw err
            }
        }
    }
}
